<template>
  <div class="col-12 dropdown_form">
    <label :for="id">{{ label }}</label>
    <multiselect
      class="select_form-multiple"
      v-model="selected"
      v-if="!!options"
      :id="id"
      :options="options"
      :searchable="true"
      :close-on-select="!multiple"
      :multiple="multiple"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      :placeholder="placeholder"
      :label="labelName"
      :showLabel="false"
      :disabled="readonly"
      :noOptions="noOptions"
    >
    </multiselect>
    <multiselect class="select_form-multiple" v-else :options="[]" :placeholder="noOptions" disabled></multiselect>
  </div>
</template>

<script>
import Vue from 'vue'
import { v4 } from 'uuid'
import Multiselect from 'vue-multiselect'

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false
    },

    placeholder: {
      type: String,
      required: false
    },

    labelName: {
      type: String,
      required: false,
      default: ''
    },

    valueName: {
      type: String,
      required: false,
      default: 'id'
    },

    value: {
      required: true,
      default: ''
    },

    rules: {
      type: Array,
      required: false
    },

    items: {
      type: Array,
      required: true
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false
    },

    emitObject: {
      type: Boolean,
      required: false,
      default: false
    },

    noOptions: {
      type: String,
      default: 'Sem opções para selecionar.'
    }
  },
  computed: {
    id () {
      return `form-field-${v4()}`
    },
    selected: {
      get () {
        if (!this.value) return null
        if (Array.isArray(this.value) && this.emitObject) return this.value

        const [selected] = this.items.filter(i => {
          if (typeof i === 'object') {
            return parseInt(i[this.valueName]) === parseInt(this.value)
          }
          return i === this.value
        })
        if (!selected) return null
        return selected
      },
      set (val) {
        if (Array.isArray(val) && this.emitObject) {
          this.$emit('input', val)
          return
        }
        if (!val) return this.$emit('input', null)
        if (typeof val !== 'object') return this.$emit('input', val)
        let input = val
        if (!this.emitObject) input = val[this.valueName]
        this.$emit('input', input)
      }
    },
    options () {
      return this.items && this.items.length ? this.items : null
    }
  },
  components: {
    Multiselect
  }
})
</script>

<style>
.dropdown_form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.input-error {
  color: #ff6e91;
  font-size: 0.9rem;
  padding-left: 0.25rem;
}

.multiselect__select,
.multiselect__input,
.multiselect__single,
.multiselect__element,
.multiselect__content,
.multiselect__select * {
  font-family: 'Roboto', sans-serif !important;
  color: #787a85 !important;
  font-size: 16px !important;
}
.no-options {
  font-size: 16px;
  font-weight: normal;
  color: #f03838;
}
</style>
